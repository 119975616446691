/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, {useState} from "react";
import { useLocation } from "react-router";
import {useSelector} from "react-redux";
import AsideHeadMenu from "./AsideHeadMenu";
import AsideLinkMenu from "./AsideLinkMenu";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const {user} = useSelector(state => state.auth);

  //make menu array list
  const [menus, setMenus] = useState([
    {
      id : "dashboard",
      path : "/dashboard",
      text : "Dashboard",
      icon : "/media/svg/icons/Design/Layers.svg",
      type : "head-menu",
      authorization : true,
      isActive : false,
      children : [
        {
          id : "dashboard-kpi",
          path : "/dashboard/kpi",
          text : "KPI",
          icon : "/media/svg/icons/General/Settings-1.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "dashboard-executive",
          path : "/dashboard/executive",
          text : "Executive",
          icon : "/media/svg/icons/General/User.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "dashboard-operation",
          path : "/dashboard/operation",
          text : "Operation",
          icon : "/media/svg/icons/Shopping/Calculator.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
      ]
    },
    {
      id : "datamaster",
      path : "/datamaster",
      text : "Data Master",
      icon : "/media/svg/icons/Design/Layers.svg",
      type : "head-menu",
      authorization : true,
      isActive : false,
      children : [
        {
          id : "datamaster-datakebun",
          path : "/datamaster/datakebun",
          text : "Data Kebun",
          icon : "/media/svg/icons/General/User.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "datamaster-datainvestor",
          path : "/datamaster/datainvestor",
          text : "Data Investor",
          icon : "/media/svg/icons/Shopping/Calculator.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "datamaster-datapembeli",
          path : "/datamaster/datapembeli",
          text : "Data Pembeli",
          icon : "/media/svg/icons/Files/File.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "datamaster-datapengelola",
          path : "/datamaster/datapengelola",
          text : "Data Pengelola",
          icon : "/media/svg/icons/Files/File.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "datamaster-datafile",
          path : "/datamaster/datafile",
          text : "Setup File",
          icon : "/media/svg/icons/General/Settings-1.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
      ]
    },
    {
      id : "operation",
      path : "/operation",
      text : "Operation",
      icon : "/media/svg/icons/Design/Layers.svg",
      type : "head-menu",
      authorization : true,
      isActive : false,
      children : [
        {
          id : "operation-panen",
          path : "/operation/panen",
          text : "Panen",
          icon : "/media/svg/icons/General/Settings-1.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
      ]
    },
    {
      id : "reporting",
      path : "/reporting",
      text : "Reporting",
      icon : "/media/svg/icons/Design/Layers.svg",
      type : "head-menu",
      authorization : true,
      isActive : false,
      children : [
        {
          id : "reporting-kebun",
          path : "/reporting/kebun",
          text : "Kebun",
          icon : "/media/svg/icons/Shopping/Price2.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "reporting-investor",
          path : "/reporting/investor",
          text : "Investor",
          icon : "/media/svg/icons/Shopping/Price2.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
      ]
    },
    {
      id : "master",
      path : "/master",
      text : "Master",
      icon : "/media/svg/icons/Design/Layers.svg",
      type : "head-menu",
      authorization : true,
      isActive : false,
      children : [
        {
          id : "master-bank",
          path : "/master/bank",
          text : "Bank",
          icon : "/media/svg/icons/Shopping/Price2.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "master-country",
          path : "/master/country",
          text : "Country",
          icon : "/media/svg/icons/Shopping/Price2.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "master-province",
          path : "/master/province",
          text : "Province",
          icon : "/media/svg/icons/Shopping/Price2.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "master-city",
          path : "/master/city",
          text : "City",
          icon : "/media/svg/icons/Shopping/Price2.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "master-district",
          path : "/master/district",
          text : "District",
          icon : "/media/svg/icons/Shopping/Price2.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "master-village",
          path : "/master/village",
          text : "Village",
          icon : "/media/svg/icons/Shopping/Price2.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
      ]
    },
    {
      id : "user",
      path : "/user",
      text : "User",
      icon : "/media/svg/icons/Design/Layers.svg",
      type : "head-menu",
      authorization : true,
      isActive : false,
      children : [
        {
          id : "user-role",
          path : "/user/role",
          text : "Role",
          icon : "/media/svg/icons/General/Settings-1.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
        {
          id : "user-account",
          path : "/user/account",
          text : "Account",
          icon : "/media/svg/icons/General/Settings-1.svg",
          type : "menu",
          authorization : true,
          isActive : false
        },
      ]
    },
  ])

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {
          menus && 
          menus.map(x => {
            if(x.authorization){
              return x.type === "menu" ? <AsideLinkMenu key={x.id} data={x} /> : <AsideHeadMenu key={x.id} data={x} />
            }
          })
        }
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialDataPembeliState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  dataPembeliForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const dataPembeliSlice = createSlice({
  name: "dataPembeli",
  initialState: initialDataPembeliState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		dataPembeliFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    dataPembeliCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.dataPembeli.push(action.payload.dataPembeli);
    },
    dataPembeliDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.dataPembeli.filter(el => el.dataPembeli_id !== action.payload.id);
    },
    getDataPembeli: (state, action) => {
      state.actionsLoading = false;
      state.dataPembeliForEdit = action.payload.dataPembeliForEdit;
      state.error = null;
    },
    // updateProduct
    dataPembeliUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.dataPembeli = state.entities.dataPembeli.map(entity => {
        if (entity.dataPembeli_id === action.payload.dataPembeli.dataPembeli_id) {
          return action.payload.dataPembeli;
        }
        return entity;
      });
    },
  },
});

import * as auth from "../app/modules/Auth/_redux/authRedux";

import {all} from "redux-saga/effects";
import {combineReducers} from "redux";
import {loadingSlice} from "../custom/_redux/loadingSlice";
import {bankSlice} from "../app/modules/master/_redux/bank/bankSlice";
import {citySlice} from "../app/modules/master/_redux/city/citySlice";
import {countrySlice} from "../app/modules/master/_redux/country/countrySlice";
import {districtSlice} from "../app/modules/master/_redux/district/districtSlice";
import {provinceSlice} from "../app/modules/master/_redux/province/provinceSlice";
import {villageSlice} from "../app/modules/master/_redux/village/villageSlice";
import {dataInvestorSlice} from "../app/modules/datamaster/_redux/dataInvestor/dataInvestorSlice";
import {dataPembeliSlice} from "../app/modules/datamaster/_redux/dataPembeli/dataPembeliSlice";
import {dataPengelolaSlice} from "../app/modules/datamaster/_redux/dataPengelola/dataPengelolaSlice";
import {dataFileSlice} from "../app/modules/datamaster/_redux/dataFile/dataFileSlice";
import {dataKebunSlice} from "../app/modules/datamaster/_redux/dataKebun/dataKebunSlice";


export const rootReducer = combineReducers({
  auth: auth.reducer,
  loading: loadingSlice.reducer,
  bank: bankSlice.reducer,
  city: citySlice.reducer,
  country: countrySlice.reducer,
  district: districtSlice.reducer,
  province: provinceSlice.reducer,
  village: villageSlice.reducer,
  dataInvestor: dataInvestorSlice.reducer,
  dataPembeli: dataPembeliSlice.reducer,
  dataPengelola: dataPengelolaSlice.reducer,
  dataFile: dataFileSlice.reducer,
  dataKebun: dataKebunSlice.reducer,
  
});

export function* rootSaga() {
  yield all([auth.saga()]);
}

import Moment from 'moment';
import { extendMoment } from 'moment-range';
 
const moment = extendMoment(Moment);

export const rangeDate = (startDate,endDate) => {
    //var name = f.name;
    //const start = moment(startDate, 'YYYY-MM-DD');
    //const end   = moment(endDate, 'YYYY-MM-DD');
    const dates = [moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')];
    const range = moment.range(dates);
    const originArrRange = Array.from(range.by('day'));
    const arrRange = originArrRange.map(x => x.format('YYYY-MM-DD'));
    
    return arrRange;
}

export function removeCSSClass(ele, cls) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const salesRetailFilter = x => ((x.role.name === "Sales Retail") || (x.role.name === "Admin Teknisi Retail"));
export const salesCorporateFilter = x => x.role.name === "Sales Corporate";
export const teknisiRetailFilter = x => ((x.role.name === "Teknisi Retail") || (x.role.name === "Teknisi Corporate") || (x.role.name === "Admin Teknisi Retail"));
export const teknisiCorporateFilter = x => ((x.role.name === "Teknisi Retail") || (x.role.name === "Teknisi Corporate"));

export const apiUrl = "https://apisawit.hulwa.co.id";
export const apiMorUrl = "https://apimor.hulwa.co.id";
import { createSlice } from "@reduxjs/toolkit";

const initialDataInvestorState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  dataInvestorForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const dataInvestorSlice = createSlice({
  name: "dataInvestor",
  initialState: initialDataInvestorState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		dataInvestorFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    dataInvestorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.dataInvestor.push(action.payload.dataInvestor);
    },
    dataInvestorDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.dataInvestor.filter(el => el.dataInvestor_id !== action.payload.id);
    },
    getDataInvestor: (state, action) => {
      state.actionsLoading = false;
      state.dataInvestorForEdit = action.payload.dataInvestorForEdit;
      state.error = null;
    },
    // updateProduct
    dataInvestorUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.dataInvestor = state.entities.dataInvestor.map(entity => {
        if (entity.dataInvestor_id === action.payload.dataInvestor.dataInvestor_id) {
          return action.payload.dataInvestor;
        }
        return entity;
      });
    },
  },
});

import {LayoutSplashScreen} from "../_metronic/layout";
import React, {Suspense, lazy} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

//import {DashboardPage} from "./pages/DashboardPage";

const DASHBOARD = lazy(() =>
  import("./modules/dashboard/DASHBOARD")
);

const DATAMASTER = lazy(() =>
  import("./modules/datamaster/DATAMASTER")
);

const MASTER = lazy(() =>
  import("./modules/master/MASTER")
);

const OPERATION = lazy(() =>
  import("./modules/operation/OPERATION")
);

const REPORTING = lazy(() =>
  import("./modules/reporting/REPORTING")
);

const User = lazy(() =>
  import("./modules/user/User")
);



export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <Route path="/dashboard" component={DASHBOARD}/>
                <Route path="/datamaster" component={DATAMASTER}/>
                <Route path="/master" component={MASTER}/>
                <Route path="/operation" component={OPERATION}/>
                <Route path="/reporting" component={REPORTING}/>
                <Route path="/user" component={User}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}

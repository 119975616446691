import { createSlice } from "@reduxjs/toolkit";

const initialDataFileState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  dataFileForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const dataFileSlice = createSlice({
  name: "dataFile",
  initialState: initialDataFileState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		dataFileFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    dataFileCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.dataFile.push(action.payload.dataFile);
    },
    dataFileDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.dataFile.filter(el => el.dataFile_id !== action.payload.id);
    },
    getDataFile: (state, action) => {
      state.actionsLoading = false;
      state.dataFileForEdit = action.payload.dataFileForEdit;
      state.error = null;
    },
    // updateProduct
    dataFileUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.dataFile = state.entities.dataFile.map(entity => {
        if (entity.dataFile_id === action.payload.dataFile.dataFile_id) {
          return action.payload.dataFile;
        }
        return entity;
      });
    },
  },
});

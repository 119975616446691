import { createSlice } from "@reduxjs/toolkit";

const initialDistrictState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  districtForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const districtSlice = createSlice({
  name: "district",
  initialState: initialDistrictState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		districtFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    districtCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.district.push(action.payload.district);
    },
    districtDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.district.filter(el => el.district_id !== action.payload.id);
    },
    getDistrict: (state, action) => {
      state.actionsLoading = false;
      state.districtForEdit = action.payload.districtForEdit;
      state.error = null;
    },
    // updateProduct
    districtUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.district = state.entities.district.map(entity => {
        if (entity.district_id === action.payload.district.district_id) {
          return action.payload.district;
        }
        return entity;
      });
    },
  },
});

import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

import { NavLink } from "react-router-dom";
import React from "react";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router";
import AsideLinkMenu from "./AsideLinkMenu";

const AsideHeadMenu = ({data}) => {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
      return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
    };
    return (
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            data.path,
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={data.path}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(data.icon)} />
            </span>
            <span className="menu-text">{data.text}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{data.text}</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              {
                  data.children.map(x => {
                    if(x.authorization){
                      return x.type === "menu" ? <AsideLinkMenu key={x.id} data={x} /> : <AsideHeadMenu key={x.id} data={x} />
                    }
                  })
              }
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
    )
}
export default AsideHeadMenu;
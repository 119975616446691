import { createSlice } from "@reduxjs/toolkit";

const initialBankState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  bankForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const bankSlice = createSlice({
  name: "bank",
  initialState: initialBankState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		bankFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    bankCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.bank.push(action.payload.bank);
    },
    bankDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.bank.filter(el => el.bank_id !== action.payload.id);
    },
    getBank: (state, action) => {
      state.actionsLoading = false;
      state.bankForEdit = action.payload.bankForEdit;
      state.error = null;
    },
    // updateProduct
    bankUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.bank = state.entities.bank.map(entity => {
        if (entity.bank_id === action.payload.bank.bank_id) {
          return action.payload.bank;
        }
        return entity;
      });
    },
  },
});

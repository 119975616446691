import { createSlice } from "@reduxjs/toolkit";

const initialCountryState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  countryForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const countrySlice = createSlice({
  name: "country",
  initialState: initialCountryState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		countryFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    countryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.country.push(action.payload.country);
    },
    countryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.country.filter(el => el.country_id !== action.payload.id);
    },
    getCountry: (state, action) => {
      state.actionsLoading = false;
      state.countryForEdit = action.payload.countryForEdit;
      state.error = null;
    },
    // updateProduct
    countryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.country = state.entities.country.map(entity => {
        if (entity.country_id === action.payload.country.country_id) {
          return action.payload.country;
        }
        return entity;
      });
    },
  },
});

import {
  toast
} from 'react-toastify';

import * as XLSX from "xlsx";
import imageCompression from 'browser-image-compression';

const data_type = [
  'data:application/pdf;',
  'data:application/msword;',
  'data:application/vnd.ms-excel;',
  'data:image/jpeg;',
  'data:image/png;',
  'data:application/x-7z-compressed;',
  'data:application/zip;',
  'data:application/x-rar-compressed;',
];

const formatDataMahasiswa = (x) => {
  return {
      nim : x.nim,
      nama : x.nama,
      no_ktp : x.no_ktp,
      no_kamar : x.no_kamar,
      no_hp : x.no_hp,
      email : x.email,
      info : x.info,
      nama_kost : x.kost.nama,
      alamat_kost : x.kost.alamat,
      kelurahan_kost : x.kost.kelurahan,
      jumlah_kamar_kost : x.kost.jumlah_kamar,
      nama_pemilik_kost : x.kost.nama_pemilik,
      hp_pemilik_kost : x.kost.hp_pemilik,
      nama_pengelola_kost : x.kost.nama_pengelola,
      hp_pengelola_kost : x.kost.hp_pengelola,
      status_kemitraan_kost : x.kost.status_kemitraan,
      masa_berlaku_mitra_kost : x.kost.masa_berlaku_mitra,
      no_pks_kost : x.kost.no_pks,
      nama_cluster_cluster : x.kost.cluster ? x.kost.cluster.nama_cluster : "",
      keterangan_cluster : x.kost.cluster ? x.kost.cluster.keterangan : "",
      nik_karyawan : x.sales.nik,
      nama_karyawan : x.sales.name,
  }
}

export const convertDataToExcelMahasiswa = (data) => {
  const record = data.map(x => formatDataMahasiswa(x));
  const wscols = [
    { wch: Math.max(...record.map(x => x.nim ? x.nim.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama ? x.nama.length : 0 )) },
    { wch: Math.max(...record.map(x => x.no_ktp ? x.no_ktp.length : 0 )) },
    { wch: Math.max(...record.map(x => x.no_kamar ? x.no_kamar.length : 0 )) },
    { wch: Math.max(...record.map(x => x.no_hp ? x.no_hp.length : 0 )) },
    { wch: Math.max(...record.map(x => x.email ? x.email.length : 0 )) },
    { wch: Math.max(...record.map(x => x.info ? x.info.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama_kost ? x.nama_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.alamat_kost ? x.alamat_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.kelurahan_kost ? x.kelurahan_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.jumlah_kamar_kost ? x.jumlah_kamar_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama_pemilik_kost ? x.nama_pemilik_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.hp_pemilik_kost ? x.hp_pemilik_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama_pengelola_kost ? x.nama_pengelola_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.hp_pengelola_kost ? x.hp_pengelola_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.status_kemitraan_kost ? x.status_kemitraan_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.masa_berlaku_mitra_kost ? x.masa_berlaku_mitra_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.no_pks_kost ? x.no_pks_kost.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama_cluster_cluster ? x.nama_cluster_cluster.length : 0 )) },
    { wch: Math.max(...record.map(x => x.keterangan_cluster ? x.keterangan_cluster.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nik_karyawan ? x.nik_karyawan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama_karyawan ? x.nama_karyawan.length : 0 )) },
  ];
  const multiDataSet = 
    {
        header: [
          "NIM",
          "Nama",
          "No KTP",
          "No Kamar",
          "No HP",
          "Email",
          "Sumber Info",
          "Nama Kost",
          "Alamat Kost",
          "Kelurahan Kost",
          "Jumlah Kamar Kost",
          "Pemilik Kost",
          "No HP Pemilik Kost",
          "Pengelola Kost",
          "No HP Pengelola Kost",
          "Status Kemitraan Kost",
          "Masa Berlaku Kemitraaan Kost",
          "No PKS Kost",
          "Nama Cluster",
          "Keterangan Cluster",
          "NIK Sales",
          "Nama Sales",
        ],
        heading: [{
          nim : "NIM",
          nama : "Nama",
          no_ktp : "No KTP",
          no_kamar : "No Kamar",
          no_hp : "No HP",
          email : "Email",
          info : "Sumber Info",
          nama_kost : "Nama Kost",
          alamat_kost : "Alamat Kost",
          kelurahan_kost : "Kelurahan Kost",
          jumlah_kamar_kost : "Jumlah Kamar Kost",
          nama_pemilik_kost : "Pemilik Kost",
          hp_pemilik_kost : "No HP Pemilik Kost",
          nama_pengelola_kost : "Pengelola Kost",
          hp_pengelola_kost : "No HP Pengelola Kost",
          status_kemitraan_kost : "Status Kemitraan Kost",
          masa_berlaku_mitra_kost : "Masa Berlaku Kemitraaan Kost",
          no_pks_kost : "No PKS Kost",
          nama_cluster_cluster : "Nama Cluster",
          keterangan_cluster : "Keterangan Cluster",
          nik_karyawan : "NIK Sales",
          nama_karyawan : "Nama Sales",
        }],
        csvHeader: [
          {label : "NIM", key : "nim"},
          {label : "Nama", key : "nama"},
          {label : "No KTP", key : "no_ktp"},
          {label : "No Kamar", key : "no_kamar"},
          {label : "No HP", key : "no_hp"},
          {label : "Email", key : "email"},
          {label : "Sumber Info", key : "info"},
          {label : "Nama Kost", key : "nama_kost"},
          {label : "Alamat Kost", key : "alamat_kost"},
          {label : "Kelurahan Kost", key : "kelurahan_kost"},
          {label : "Jumlah Kamar Kost", key : "jumlah_kamar_kost"},
          {label : "Pemilik Kost", key : "nama_pemilik_kost"},
          {label : "No HP Pemilik Kost", key : "hp_pemilik_kost"},
          {label : "Pengelola Kost", key : "nama_pengelola_kost"},
          {label : "No HP Pengelola Kost", key : "hp_pengelola_kost"},
          {label : "Status Kemitraan Kost", key : "status_kemitraan_kost"},
          {label : "Masa Berlaku Kemitraaan Kost", key : "masa_berlaku_mitra_kost"},
          {label : "No PKS Kost", key : "no_pks_kost"},
          {label : "Nama Cluster", key : "nama_cluster_cluster"},
          {label : "Keterangan Cluster", key : "keterangan_cluster"},
          {label : "NIK Sales", key : "nik_karyawan"},
          {label : "Nama Sales", key : "nama_karyawan"},
        ],
        fileName : "Data_Mahasiswa",
        csvFileName : "Data_Mahasiswa.csv",
        wscols : wscols,
        csvData: record
    };
  return multiDataSet;
  //return newBlob;
};

const formatDataResidential = (x) => {
  return {
      nama : x.nama,
      no_ktp : x.no_ktp,
      alamat : x.alamat,
      no_hp : x.no_hp,
      email : x.email,
      info : x.info,
      nama_cluster : x.cluster ? x.cluster.nama_cluster : "",
      keterangan_cluster : x.cluster ? x.cluster.keterangan : "",
      nik_karyawan : x.sales.nik,
      nama_karyawan : x.sales.name,
  }
}

export const convertDataToExcelResidential = (data) => {
  const record = data.map(x => formatDataResidential(x));
  const wscols = [
    { wch: Math.max(...record.map(x => x.nama ? x.nama.length : 0 )) },
    { wch: Math.max(...record.map(x => x.no_ktp ? x.no_ktp.length : 0 )) },
    { wch: Math.max(...record.map(x => x.alamat ? x.alamat.length : 0 )) },
    { wch: Math.max(...record.map(x => x.no_hp ? x.no_hp.length : 0 )) },
    { wch: Math.max(...record.map(x => x.email ? x.email.length : 0 )) },
    { wch: Math.max(...record.map(x => x.info ? x.info.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama_cluster_cluster ? x.nama_cluster_cluster.length : 0 )) },
    { wch: Math.max(...record.map(x => x.keterangan_cluster ? x.keterangan_cluster.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nik_karyawan ? x.nik_karyawan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama_karyawan ? x.nama_karyawan.length : 0 )) },
  ];
  const multiDataSet = 
    {
        header: [
          "Nama",
          "No KTP",
          "Alamat",
          "No HP",
          "Email",
          "Sumber Info",
          "Nama Cluster",
          "Keterangan Cluster",
          "NIK Sales",
          "Nama Sales",
        ],
        heading: [{
          nama : "Nama",
          no_ktp : "No KTP",
          alamat : "Alamat",
          no_hp : "No HP",
          email : "Email",
          info : "Sumber Info",
          nama_cluster : "Nama Cluster",
          keterangan_cluster : "Keterangan Cluster",
          nik_karyawan : "NIK Sales",
          nama_karyawan : "Nama Sales",
        }],
        csvHeader: [
          {label : "Nama", key : "nama"},
          {label : "No KTP", key : "no_ktp"},
          {label : "Alamat", key : "alamat"},
          {label : "No HP", key : "no_hp"},
          {label : "Email", key : "email"},
          {label : "Sumber Info", key : "info"},
          {label : "Nama Cluster", key : "nama_cluster"},
          {label : "Keterangan Cluster", key : "keterangan_cluster"},
          {label : "NIK Sales", key : "nik_karyawan"},
          {label : "Nama Sales", key : "nama_karyawan"},
        ],
        fileName : "Data_Residential",
        csvFileName : "Data_Residential.csv",
        wscols : wscols,
        csvData: record
    };
  return multiDataSet;
  //return newBlob;
};

const formatDataRequest = (x) => {
  return {
      request_id : x.request_id,
      sewa_wifi : x.sewa_wifi,
      tanggal_request : x.tanggal_request,
      status : x.status,
      type : x.type,
      nim_pelanggan : x.pelanggan.nim ? x.pelanggan.nim : "",
      nama_pelanggan : x.pelanggan.nama,
      no_ktp_pelanggan : x.pelanggan.no_ktp,
      alamat_pelanggan : x.pelanggan.kost ? x.pelanggan.kost.nama : x.pelanggan.alamat,
      no_kamar_pelanggan : x.pelanggan.no_kamar ? x.pelanggan.no_kamar : "",
      no_hp_pelanggan : x.pelanggan.no_hp,
      email_pelanggan : x.pelanggan.email,
      info_pelanggan : x.pelanggan.info,
      nama_cluster : x.pelanggan.cluster ? x.pelanggan.cluster.nama_cluster : (x.pelanggan.kost.cluster ? x.pelanggan.kost.cluster.nama_cluster : ""),
      keterangan_cluster : x.pelanggan.cluster ? x.pelanggan.cluster.keterangan : (x.pelanggan.kost.cluster ? x.pelanggan.kost.cluster.keterangan : ""),
      nik_karyawan : x.pelanggan.sales.nik,
      nama_karyawan : x.pelanggan.sales.name,
  }
}

export const convertDataToExcelRequest = (data) => {
  const record = data.map(x => formatDataRequest(x));
  const wscols = [
    { wch: Math.max(...record.map(x => x.request_id ? x.request_id.length : 0 )) },
    { wch: Math.max(...record.map(x => x.sewa_wifi ? x.sewa_wifi.length : 0 )) },
    { wch: Math.max(...record.map(x => x.tanggal_request ? x.tanggal_request.length : 0 )) },
    { wch: Math.max(...record.map(x => x.status ? x.status.length : 0 )) },
    { wch: Math.max(...record.map(x => x.type ? x.type.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nim_pelanggan ? x.nim_pelanggan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama_pelanggan ? x.nama_pelanggan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.no_ktp_pelanggan ? x.no_ktp_pelanggan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.alamat_pelanggan ? x.alamat_pelanggan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.no_kamar_pelanggan ? x.no_kamar_pelanggan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.no_hp_pelanggan ? x.no_hp_pelanggan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.email_pelanggan ? x.email_pelanggan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.info_pelanggan ? x.info_pelanggan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama_cluster ? x.nama_cluster.length : 0 )) },
    { wch: Math.max(...record.map(x => x.keterangan_cluster ? x.keterangan_cluster.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nik_karyawan ? x.nik_karyawan.length : 0 )) },
    { wch: Math.max(...record.map(x => x.nama_karyawan ? x.nama_karyawan.length : 0 )) },
  ];
  const multiDataSet = 
    {
        header: [
          "Request ID",
          "Sewa Wifi",
          "Tanggal Request",
          "Status",
          "Type",
          "NIM Pelanggan",
          "Nama Pelanggan",
          "No KTP Pelanggan",
          "Alamat Pelanggan",
          "No Kamar Pelanggan",
          "No HP Pelanggan",
          "Email Pelanggan",
          "Info Pelanggan",
          "Nama Cluster",
          "Keterangan Cluster",
          "NIK Sales",
          "Nama Sales",
        ],
        heading: [{
          request_id : "Request ID",
          sewa_wifi : "Sewa Wifi",
          tanggal_request : "Tanggal Request",
          status : "Status",
          type : "Type",
          nim_pelanggan : "NIM Pelanggan",
          nama_pelanggan : "Nama Pelanggan",
          no_ktp_pelanggan : "No KTP Pelanggan",
          alamat_pelanggan : "Alamat Pelanggan",
          no_kamar_pelanggan : "No Kamar Pelanggan",
          no_hp_pelanggan : "No HP Pelanggan",
          email_pelanggan : "Email Pelanggan",
          info_pelanggan : "Info Pelanggan",
          nama_cluster : "Nama Cluster",
          keterangan_cluster : "Keterangan Cluster",
          nik_karyawan : "NIK Sales",
          nama_karyawan : "Nama Sales",
        }],
        csvHeader: [
          {label :"Request ID",key : "request_id"},
          {label :"Sewa Wifi",key : "sewa_wifi"},
          {label :"Tanggal Request",key : "tanggal_request"},
          {label :"Status",key : "status"},
          {label :"Type",key : "type"},
          {label :"NIM Pelanggan",key : "nim_pelanggan"},
          {label :"Nama Pelanggan",key : "nama_pelanggan"},
          {label :"No KTP Pelanggan",key : "no_ktp_pelanggan"},
          {label :"Alamat Pelanggan",key : "alamat_pelanggan"},
          {label :"No Kamar Pelanggan",key : "no_kamar_pelanggan"},
          {label :"No HP Pelanggan",key : "no_hp_pelanggan"},
          {label :"Email Pelanggan",key : "email_pelanggan"},
          {label :"Info Pelanggan",key : "info_pelanggan"},
          {label :"Nama Cluster",key : "nama_cluster"},
          {label :"Keterangan Cluster",key : "keterangan_cluster"},
          {label :"NIK Sales",key : "nik_karyawan"},
          {label :"Nama Sales",key : "nama_karyawan"},
        ],
        fileName : "Data_FAB",
        csvFileName : "Data_FAB.csv",
        wscols : wscols,
        csvData: record
    };
  return multiDataSet;
  //return newBlob;
};

export const convertBase64ToFile = (file) => {
  const mime = data_type.filter(x => x.includes(file.match(/^data:.*;/g)));
  if (mime.length === 0) {
    toast.error("unsupported type file");
    return null;
  }
  const byteString = atob(file.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: mime[0].replace(/(data:|;)+/g, ""),
  });
  console.log(newBlob);
  var csvURL = window.URL.createObjectURL(newBlob);
  let tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', 'filename');
  tempLink.click();
  //return newBlob;
};

export const convertBase64ToBlob = (file) => {
  const mime = data_type.filter(x => x.includes(file.match(/^data:.*;/g)));
  if (mime.length === 0) {
    toast.error("unsupported type file");
    return null;
  }
  const byteString = atob(file.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: mime[0].replace(/(data:|;)+/g, ""),
  });
  return window.URL.createObjectURL(newBlob);
};

//async compress photo
const compressFile = async(file) => {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1024,
    useWebWorker: true
  }
  try{
    const compressedFile = await imageCompression(file, options);
    //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    return { status:true ,file :compressedFile};
  }catch(error){
    console.log(error);
    return { status:false ,error :error};
  }
}

export const getBase64 = (file, cb) => {
  if(file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/pdf'){
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  }else{
    const compressedFile = compressFile(file);
    compressedFile.then((response) => {
      //dispatch(actions.progressUpdated({ progress }));
      let reader = new FileReader();
      
      reader.readAsDataURL(response.file);
      reader.onload = function() {
        cb(reader.result);
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    });
  }
  
};

export const convertExcelToJson = (f,cb) => {
  //var name = f.name;
  const reader = new FileReader();
  reader.readAsBinaryString(f);
  reader.onload = (evt) => {
    // evt = on_file_select event
    /* Parse data */
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: "binary" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[1];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_csv(ws, { header: 1, FS: "|" });
    /* Update state */
    //console.log("Data>>>" + data);// shows that excel data is read
    //console.log(convertToJson(data)); // shows data in json format
    cb(convertToJson(data));
  };
}

const convertToJson = csv => {
  var lines = csv.split("\n");

  var result = [];

  var headers = lines[0].split("|");

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split("|");

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  return result; //JSON
}


import { createSlice } from "@reduxjs/toolkit";

const initialVillageState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  villageForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const villageSlice = createSlice({
  name: "village",
  initialState: initialVillageState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		villageFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    villageCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.village.push(action.payload.village);
    },
    villageDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.village.filter(el => el.village_id !== action.payload.id);
    },
    getVillage: (state, action) => {
      state.actionsLoading = false;
      state.villageForEdit = action.payload.villageForEdit;
      state.error = null;
    },
    // updateProduct
    villageUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.village = state.entities.village.map(entity => {
        if (entity.village_id === action.payload.village.village_id) {
          return action.payload.village;
        }
        return entity;
      });
    },
  },
});

import * as Yup from "yup";
import * as auth from "../_redux/authRedux";

import { FormattedMessage, injectIntl } from "react-intl";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { login, pushToken } from "../_redux/authCrud";
import styled from "styled-components";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { useFormik } from "formik";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  nik: "",
  password: "",
};

const LoginWrapper = styled.div`
  position: absolute;
  top: 65%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: rgb(71, 117, 41, 0.8);
  color: #fff;
  padding: 1rem;
  width: 90vw;
  border-radius: 0.5rem;
  @media only screen and (min-width: 1141px){
    padding: 3rem;
    top: 60%;
    width: 30vw;
  }
`;

const InputLogin = styled.input`
  border: none;
  border-bottom: 1px solid #fff;
  font-weight: bold;
  border-radius: 0;
  &::placeholder {
    color: #fff;
    opacity: 1;
  }
`;

const Title = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Logos = styled.div`
  position: absolute;
  top: 15%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 10vw;
  height: 20vh;
  min-width: 80px;
  @media only screen and (min-width: 1141px){
    width: 8vw;
    height: 20vh;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`;

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    nik: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  // const getInputClasses = (fieldname) => {
  //   if (formik.touched[fieldname] && formik.errors[fieldname]) {
  //     return "is-invalid";
  //   }

  //   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
  //     return "is-valid";
  //   }

  //   return "";
  // };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.nik, values.password)
          .then(({ data }) => {
            disableLoading();
            props.login(data);
            return data
          })
          .then((data) => {
            pushToken(data ? `Bearer ${data.token}` : null)
              .catch(() => {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN",
                  })
                );
              });
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <Wrapper>
      <Title>
        <h4>Sistem Informasi Kelapa Sawit Terpadu</h4>
      </Title>
      <Logos>
        <img
          src={`${toAbsoluteUrl("/media/logos/sawit.png")}`}
          width="100%"
          height="100%"
          alt="SAWIT"
        />
      </Logos>
      
      <LoginWrapper>
        <h1><FormattedMessage id="AUTH.LOGIN.TITLE" /> </h1>
        <form className="form row" id="kt_login_signin_form" onSubmit={formik.handleSubmit}>
          <div className="form-group col-md-6 col-sm-12 col-xs-12">
            <InputLogin
              className="form-control h-auto text-white bg-white-o-5"
              type="text"
              placeholder="NIK"
              name="nik"
              autocomplete="off"
              {...formik.getFieldProps("nik")}
            />
            {formik.touched.nik && formik.errors.nik ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.nik}</div>
            </div>
          ) : null}
          </div>
          <div className="form-group col-md-6 col-sm-12 col-xs-12">
            <InputLogin
              className="form-control h-auto text-white bg-white-o-5"
              type="password"
              placeholder="Password"
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
          </div>
          <div className="form-group col-md-6 col-sm-12 col-xs-12">
            <div className="checkbox-inline">
              <label className="checkbox checkbox-outline checkbox-white text-white m-0">
                <input type="checkbox" name="remember" />
                <span></span>
                Remember me
              </label>
            </div>
          </div>
          <div className="form-group col-md-6 col-sm-12 col-xs-12">
            <Link to="/auth/forgot-password"
              id="kt_login_forgot"
              className="text-white font-weight-bold"
            >
              Forget Password ?
            </Link>
          </div>
          <div className="form-group col-6">
            <button
              type="submit"
              id="kt_login_signin_submit"
              className="btn btn-success btn-block"
              disabled={formik.isSubmitting}
            >
              <span>Login</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
          <div className="form-group col-6">
            <Link to="/auth/registration"
              id="kt_login_signup"
              className="btn btn-warning btn-block"
            >
              Register
            </Link>
          </div>
        </form>
      </LoginWrapper>
    </Wrapper>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
